import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
  }

  async onLogin() {
    const player = await this.auth.loginWithEmail(this.email, this.password);
    if(!player) {
      return;
    }
    this.router.navigate(['team-admin']);
  }

  async forgotPassword() {
    this.auth.resetPassword(this.email);
  }
}
