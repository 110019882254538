import { UtilService } from '../services/util.service';
import { Rating } from '../models/rating.model';

const util = new UtilService();

export class Player {

  id: string;
  firstName: string;
  lastName: string;
  email: string;
  teamId: string;
  phone: string;
  isTeamCaptain: boolean;
  isAnnonymous: boolean;
  rating: Rating;
  paid: Boolean;

  constructor(id, firstName, lastName, email, phone, isAnnonymous, isTeamCaptain, teamId, rating: Rating, paid) {
    this.id = id;
    this.firstName = firstName || "";
    this.lastName = lastName || "";
    this.email = email || "";
    this.phone = phone || null;
    this.teamId = teamId;
    this.isTeamCaptain = Boolean(isTeamCaptain);
    this.isAnnonymous = isAnnonymous;
    this.rating = rating;
    this.paid = paid || false;
  }

  static fromFirebase(record: any) {
    const rating = record.rating ? Rating.fromFirebase(record.rating) : Rating.getBlankRating();
    return new Player(record.id, record.firstName, record.lastName, record.email, record.phone, record.isAnnonymous,
      record.isTeamCaptain, record.teamId, rating, record.paid);
  }
}
