import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


import { UtilService } from './util.service';
import { Player } from '../models/player.model';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private firestore: AngularFirestore, private util: UtilService) { }

  getPlayers() {
    return this.firestore.collection('players').valueChanges();
  }

  getPlayerById(id: string) {
    const promise: Promise<Player> = new Promise((resolve, reject) => {
      // TODO: Clean up this query to only subscribe once & limit to 1.
      this.firestore.collection('players', ref => ref.where('id', '==', id)).valueChanges().subscribe((users: Array<Player>) => {
        if(!users[0]) {
          resolve(null);
          return;
        }
        const player = Player.fromFirebase(users[0]);
        resolve(player);
      });
    });
    return promise;
  }

  createPlayer(player: Player, options = {}){
    const playerObj = this.util.cleanObj(player);
    return this.firestore.collection('players').doc(playerObj.id).set(playerObj);
  }

  updatePlayer(player: Player){
    const playerObj = this.util.cleanObj(player);
    this.firestore.doc('players/' + player.id).update(playerObj);
  }

  deletePlayer(playerId: string){
      this.firestore.doc('players/' + playerId).delete();
  }
}
