import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { PlayerService } from '../../services/player.service';
import { TeamsService } from '../../services/teams.service';
import { AuthService } from '../../services/auth.service';

import { Player } from '../../models/player.model';
import { Team } from '../../models/team.model';

@Component({
  selector: 'app-register-view',
  templateUrl: './register-view.component.html',
  styleUrls: ['./register-view.component.less']
})
export class RegisterViewComponent implements OnInit {
  model: any;
  password: string;

  constructor(private playerService: PlayerService, private teamsService: TeamsService, 
    private router: Router, private auth: AuthService) {
    this.model = {};
  }

  async ngOnInit() {

  }

  async onSubmit() {
    await this.auth.logout();
    const resp = await this.auth.signUpWithEmail(this.model.email, this.password);
    if(!resp) {
      return;
    }
    const { id, email } = resp;
    const player = _.cloneDeep(this.model);
    player.id = id;
    player.email = email;
    await this.playerService.createPlayer(player);
    await this.auth.loginWithEmail(email, this.password);
    this.router.navigate(['/select-team']);
  }

  async forgotPassword() {
    this.auth.resetPassword();
  }
}
