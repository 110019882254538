import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { PlayerService } from './player.service';

import { Player } from '../models/player.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  player: Player;

  constructor(private firestore: AngularFirestore, private afAuth: AngularFireAuth, 
    private playerService: PlayerService) { }

  async signUpWithEmail(email, password) {
    try {
      let resp = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
      return { email: resp.user.email, id: resp.user.uid };
    } catch(err) {
      alert(err.message);
      return null;
    }
  }

  async signUpWithGoogle() {
    try {
      let resp = await this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
      return { email: resp.user.email, id: resp.user.uid };
    } catch(err) {
      alert(err.message);
      return null;
    }
  }

  async loginWithEmail(email, password) {
    
    if(this.player && this.player.email === email) {
      return this.player;
    }

    try {
      let resp = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      let id = resp.user.uid;
      this.player = await this.playerService.getPlayerById(id);
      return this.player;
    } catch(err) {
      alert(err.message);
      return null;
    }
  }

  async login() {
    const promise = new Promise((resolve, reject) => {
      this.afAuth.auth.onAuthStateChanged(async (user) => {
        if (user) {
          this.player = await this.playerService.getPlayerById(user.uid);
          resolve(this.player);
        }
      });
    });
    return promise;
  }

  async resetPassword(email?: string) {
    if(!email) {
      email = prompt("Enter email: ");
    }
    this.afAuth.auth.sendPasswordResetEmail(email).then(() => {
      alert("Password reset email sent successfully");
    }).catch((error) => {
      alert(error.message);
    });
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  async getPlayer() {
    if(!this.player) { 
      await this.login();
    }
    return this.player;
  }
}
