import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as _ from 'lodash';

import countries from "../../../assets/countries/countries.json"

import { Mode } from '../../enums/mode.enum';
import { Team } from '../../models/team.model';
import { Country } from '../../models/country.model';

import { TeamsService } from '../../services/teams.service';
import { AuthService } from '../../services/auth.service';
import { PlayerService } from '../../services/player.service';
import { TeamDataService } from '../../services/team-data.service';
import { UtilService } from 'src/app/services/util.service.js';

@Component({
  selector: 'app-select-team',
  templateUrl: './select-team.component.html',
  styleUrls: ['./select-team.component.less']
})
export class SelectTeamComponent implements OnInit {
  
  // Expose enum to template
  Mode = Mode;

  selectionMode: Mode = Mode.NotSpecified;
  teams$: any = [{}];
  teams: Array<Team>;
  countries: Array<Country> = countries;
  model: any = {};

  constructor(private teamsService: TeamsService, private router: Router, private auth: AuthService,
    private playerService: PlayerService, private teamDataService: TeamDataService, private util: UtilService) { }

  async ngOnInit() {
    this.teams$ = await this.teamDataService.getTeamsWithPlayers();
    this.teams$.subscribe((teams) => {
      let teamsByCountryId = new Set();
      _.each(teams, (team) => {
        teamsByCountryId.add(team.country.id);
      });

      this.countries = _.filter(this.countries, (country) => {
        return !teamsByCountryId.has(country.id);
      });

      this.teams = teams;
    });
  }

  selectMode(mode: Mode) {
    this.selectionMode = mode;
  }

  async selectTeam(country: Country) {
    const player = await this.auth.getPlayer();
    const team = new Team(country, this.util.guid(), { playerIdList: [player.id] });
    await this.teamsService.createTeam(team);
    player.teamId = team.id;
    player.isTeamCaptain = true; 
    this.playerService.updatePlayer(player);
    this.router.navigate(['/team-admin']);
  }

  async joinTeam(team: Team) {
    const player = await this.auth.getPlayer();
    const enteredPassword = prompt("Enter team password: ");
    if(enteredPassword !== team.password) {
      alert("Invalid password entered please try again.");
      return false;
    }
    team.playerIdList.push(player.id);
    player.teamId = team.id;
    await this.playerService.updatePlayer(player);
    await this.teamsService.updateTeam(team);
    this.router.navigate(['/team-admin']);
  }

}
