import { Component, OnInit } from '@angular/core';
import { TeamDataService } from 'src/app/services/team-data.service';
import { Team } from 'src/app/models/team.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.less']
})
export class LeaderboardComponent implements OnInit {
  teams$: Observable<Array<Team>>;

  constructor(private teamsDataService: TeamDataService) { }

  async ngOnInit() {
    this.teams$ = await this.teamsDataService.getTeamsWithPlayers();
  }

}
