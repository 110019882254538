import { UtilService } from '../services/util.service';
import { Country } from '../models/country.model';
import { Player } from '../models/player.model';

const util = new UtilService();

export class Team {
  country: Country;
  flag: string;
  id: string;
  playerIdList: Array<string>;
  displayName: string;
  year: number;
  password: string;
  players?: Array<Player>;

  constructor(country: Country, password = util.guid(), options: any = {}) {
    this.country = country;
    this.flag = country.alpha2;
    this.displayName = country.name;
    this.id = util.guid();
    this.playerIdList = options.playerIdList || [];
    this.year = new Date().getFullYear();
    this.password = password;
  }

  static fromFirebase(record: any) {
    return new Team(record.country, record.password, {
      playerIdList: record.playerIdList
    });
  }

  static getBlankTeam() {
    return new Team(Country.getBlank(), "");
  }
}
