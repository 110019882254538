import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Player } from 'src/app/models/player.model';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent implements OnInit {
  player: Player;

  constructor(private auth: AuthService) { }

  async ngOnInit() {
    this.player = await this.auth.getPlayer();
  }

  async logout() {
    try {
      await this.auth.logout();
      this.player = null;
      alert("Logged out successfully");
    } catch(ex) {
      console.error(ex);
      alert("Failed to log out");
    }
  }
}
