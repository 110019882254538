import { Component } from '@angular/core';

import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'Beer Chess Olympics';

  constructor(private auth: AuthService) {}

  async ngOnInit() {
    await this.auth.login();
  }
}
