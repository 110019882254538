import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';

import * as _ from 'lodash';

import { PlayerService } from './player.service';
import { TeamsService } from './teams.service';
import { Player } from '../models/player.model';


@Injectable({
  providedIn: 'root'
})
export class TeamDataService {

  constructor(private playerService: PlayerService, private teamsService: TeamsService) { }

  async getTeamsWithPlayers() {
    const teams$ = await this.teamsService.getTeams();
    const getTeamsWithPlayers$ = teams$
      .pipe(
        mergeMap(async (teams: any) => {
          await Promise.all(_.map(teams, async (team) => {
            const players = [];
            for(let playerId of team.playerIdList) {
              const player = await this.playerService.getPlayerById(playerId);
              players.push(player);
            }
            team.players = players;
            team.ratings = this._getAverageRatings(team.players);
          }))
          return teams;
        })
      )
    return getTeamsWithPlayers$;
  }

  async getTeamByIdWithPlayers(id: string) {
    const team = await this.teamsService.getTeamById(id);
    const players = [];
    for(let playerId of team.playerIdList) {
      const player = await this.playerService.getPlayerById(playerId);
      players.push(player);
    }
    team.players = players;
    return team;
  }

  _getAverageRatings(players: Array<Player>) {
    let drinkingRatingSum = 0,
      gamesRatingSum = 0, 
      chessRatingSum = 0;

    _.each(players, (player) => {
      drinkingRatingSum += player.rating.drinking;
      gamesRatingSum += player.rating.games;
      chessRatingSum += player.rating.chess;
    });
    let drinkingAvg = _.round(drinkingRatingSum/players.length);
    let gamesAvg = _.round(gamesRatingSum/players.length);
    let chessAvg = _.round(chessRatingSum/players.length);

    return {
      drinking: drinkingAvg,
      games: gamesAvg,
      chess: chessAvg,
      overall: _.round((drinkingAvg + gamesAvg + chessAvg)/3)
    }
  }
}
