import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

import { UtilService } from './util.service';
import { PlayerService } from './player.service';
import { Team } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private firestore: AngularFirestore, private util: UtilService) { }

  async getTeams() {
    return this.firestore.collection('teams').valueChanges();
  }

  getTeamById(id: string) {
    const promise: Promise<Team> = new Promise((resolve, reject) => {
      // TODO: Clean up this query to only subscribe once & limit to 1.
      this.firestore.collection('teams', ref => ref.where('id', '==', id)).valueChanges().subscribe((teams: Array<Team>) => {
        if(!teams[0]) {
          resolve(null);
          return;
        }
        const team = Team.fromFirebase(teams[0]);
        resolve(team);
      });
    });
    return promise;
  }

  async createTeam(team: Team){
    const teamObj = this.util.cleanObj(team);
    return this.firestore.collection('teams').doc(team.id).set(teamObj);
  }

  async updateTeam(team: Team){
    const teamObj = this.util.cleanObj(team);
    this.firestore.doc('teams/' + team.id).update(teamObj);
  }

  async deleteTeam(teamId: string){
  }
}
