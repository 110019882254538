import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';


import { environment } from '../environments/environment';

import { HomeViewComponent } from './views/home-view/home-view.component';
import { RegisterViewComponent } from './views/register-view/register-view.component';
import { SelectTeamComponent } from './views/select-team/select-team.component';
import { TeamAdminComponent } from './views/team-admin/team-admin.component';
import { NavComponent } from './components/nav/nav.component';
import { LeaderboardComponent } from './views/leaderboard/leaderboard.component';
import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ModalComponent } from './components/modal/modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeViewComponent,
    RegisterViewComponent,
    SelectTeamComponent,
    TeamAdminComponent,
    NavComponent,
    LeaderboardComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  providers: [AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
