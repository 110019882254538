export class Country {
  id: number;
  name: string;
  alpha2: string; // Flag file name
  alpha3: string; // Unclear...

  constructor(id: string, name: string, alpha2: string, alpha3: string) {}

  static getBlank() {
    return new Country("", "", "", "");
  }

}
