import { Component, OnInit } from '@angular/core';
import {Http, Headers, URLSearchParams} from '@angular/http';

import { TeamsService } from '../../services/teams.service';
import { AuthService } from '../../services/auth.service';
import { TeamDataService } from '../../services/team-data.service';

import { Team } from '../../models/team.model';
import { Player } from 'src/app/models/player.model';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-team-admin',
  templateUrl: './team-admin.component.html',
  styleUrls: ['./team-admin.component.less']
})
export class TeamAdminComponent implements OnInit {
  Math: Math = Math;
  team: Team = Team.getBlankTeam();
  player: Player;
  payDuesModalOpen: boolean = false;

  constructor(private auth: AuthService, private teamsService: TeamsService, 
    private teamDataService: TeamDataService, private http: Http, private playerService: PlayerService) { }

  async ngOnInit() {
    this.player = await this.auth.getPlayer();
    this.team = await this.teamDataService.getTeamByIdWithPlayers(this.player.teamId);
  }

  openPayDuesModal() {
    this.payDuesModalOpen = true;
  }

  async onPayDuesConfirmation() {
    try {
      this.player.paid = true;
      await this.playerService.updatePlayer(this.player);
    } catch(err) {
      alert(err.message);
    }
    this.payDuesModalOpen = false;
  }

  onClosePayDues() {
    this.payDuesModalOpen = false;
  }

  async invitePlayer() {
    const email = prompt("Enter players email: ");
    this.http.post('https://us-central1-beer-chess-olympics-web.cloudfunctions.net/sendEmail', {
      email, 
      from: `${this.player.firstName} ${this.player.lastName}`,
      team: this.team.displayName,
      password: this.team.password
    }).subscribe((resp) => {
      alert("Email sent to: " + email);
    })
  }

  async save(player: Player) {
    try {
      await this.playerService.updatePlayer(player);
      alert("Updated player successfully");
    } catch(err) {
      alert(err.message);
    }
  }

}
