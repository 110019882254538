export class Rating {
  drinking: number;
  games: number;
  chess: number;
  overall: number;

  constructor(drinkingRating, gamesRating, chessRating) {
    this.drinking = drinkingRating;
    this.games = gamesRating;
    this.chess = chessRating;
    this.overall = Math.round((drinkingRating + gamesRating + chessRating)/3);
  }

  static getBlankRating() {
    return new Rating(0, 0, 0);
  }

  static fromFirebase(record: any) {
    return new Rating(record.drinking, record.games, record.chess);
  }

}
